export const FirebaseConfig = {
	"projectId": "sengo-aeefe",
	"appId": "1:445347752268:web:77e7b530f0e6cb7a9807e9",
	"databaseURL": "https://sengo-aeefe-default-rtdb.firebaseio.com",
	"storageBucket": "sengo-aeefe.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyB4fNDuoL9JhiLLkcHOcYv1q9i5h6M3pUM",
	"authDomain": "sengo-aeefe.firebaseapp.com",
	"messagingSenderId": "445347752268",
	"measurementId": "G-7YYSF44NMH"
};